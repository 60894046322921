import React, { lazy } from 'react';
import { dashboardPagesMenu, demoPagesMenu, screenerPagesMenu, watchlistPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const SCREENER_LIST = {
	BOXED: lazy(() => import('../pages/presentation/screener/ScreenerBrowseView')),
};

const WATCH_LIST = {
	BOXED: lazy(() => import('../pages/presentation/watchlist/WatchListPage')),
};

const INSTRUMENT = {
	BOXED: lazy(() => import('../pages/presentation/instruments/InstrumentPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	/* {
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	}, */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <SCREENER_LIST.BOXED />,
	},
	{
		path: screenerPagesMenu.filter.path,
		element: <SCREENER_LIST.BOXED />,
	},
	{
		path: watchlistPagesMenu.watchlist.path,
		element: <WATCH_LIST.BOXED />,
	},
	{
		path: `stocks/:symbol`,
		element: <INSTRUMENT.BOXED />,
	},

	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},
];
const contents = [...presentation];

export default contents;
