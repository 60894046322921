import React from 'react';
import Popovers from 'components/bootstrap/Popovers';
import Footer from '../../../layout/Footer/Footer';

const DefaultFooter = () => {
	const visible = false;

	if (!visible) return <br />;

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<Popovers
							title='Footer'
							desc={<code>src/pages/_layout/_footers/DefaultFooter.js</code>}>
							Footer
						</Popovers>
						<code className='ps-3'>DefaultFooter.js</code>
					</div>
					<div className='col-auto'>
						<Popovers
							title='Footer'
							desc={<code>src/pages/_layout/_footers/DefaultFooter.js</code>}>
							Footer
						</Popovers>
						<code className='ps-3'>DefaultFooter.js</code>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
