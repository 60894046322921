import React from 'react';
import DefaultAside from 'pages/_layout/_asides/DefaultAside';
import { demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';

const asides = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
